import type { UserTarget, RepoTarget, TypedTarget } from "@openqlabs/drm-db";
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api, type RouterOutputs } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTargetContact } from "./TargetContactProvider";

type RefetchFunc = () => Promise<void>;
type TargetOpts = RepoTarget | UserTarget | null;

const TargetContext = createContext<
  | {
      target: TargetOpts;
      refetchTarget: RefetchFunc;
      isEvaluating: boolean;
      setIsEvaluating: Dispatch<SetStateAction<boolean>>;
      targetWithChildEvaluations: RouterOutputs["githubTarget"]["getWithChildEvaluations"];
    }
  | object
>({});

export function useTarget<T>() {
  const context = useContext(TargetContext) as unknown as {
    target: TypedTarget<T> | null;
    refetchTarget: RefetchFunc;
    isEvaluating: boolean;
    setIsEvaluating: Dispatch<SetStateAction<boolean>>;
    targetWithChildEvaluations: RouterOutputs["githubTarget"]["getWithChildEvaluations"];
  };
  return context;
}

interface ProviderProps {
  githubTargetId?: string;
  initialTarget?: TargetOpts;
  children: React.ReactNode;
}

export function GithubTargetProvider({
  children,
  initialTarget = null,
}: ProviderProps) {
  const logger = useLogger();
  if (!initialTarget)
    logger.info("no initial target", "GithubTargetProvider.tsx");
  const [isEvaluating, setIsEvaluating] = useState(false);
  const githubTargetId = useTargetContact()?.targetContact?.githubTargetId;
  const { activeTeamAccount } = useTeamAccount();
  const { refetch, data: target } = api.githubTarget.get.useQuery({
    githubTargetId,
    teamAccountId: activeTeamAccount.id,
  });

  const { data: targetWithChildEvaluations } =
    api.githubTarget.getWithChildEvaluations.useQuery({
      githubTargetId,
      teamAccountId: activeTeamAccount.id,
    });

  const refetchTarget = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <TargetContext.Provider
      value={{
        target,
        targetWithChildEvaluations,
        refetchTarget,
        isEvaluating,
        setIsEvaluating,
      }}
    >
      {children}
    </TargetContext.Provider>
  );
}
