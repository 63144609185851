import { useCallback, useEffect, useReducer, type ReactNode } from "react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import ComposerContext from "./ComposerContext";
import { type ComposerContextType } from "./ComposerContextType";
import InitialState from "./ComposerInitialState";
import ComposerReducer from "./ComposerReducer";

const ComposerProvider = ({
  children,
  to,
  messageId,
  emailThreadId,
  subject,
  initialComposeType,
  setInitialComposeType,
  refetch,
}: {
  children: ReactNode;
  to?: string[];
  messageId?: string;
  emailThreadId?: string;
  subject?: string;
  initialComposeType?: string;
  refetch: () => void;
  setInitialComposeType: (composeType: string) => void;
}) => {
  const [teamAccountUser] = useTeamAccountUser();
  const initializerFunction = useCallback(() => {
    return {
      ...InitialState,
      from: teamAccountUser?.email ? [teamAccountUser?.email] : [],
      to: to ?? InitialState.to,
      messageId: messageId ?? "",
      emailThreadId: emailThreadId ?? "",
      subject: subject ?? InitialState.subject,
      getInitial: initializerFunction,
      composeType: initialComposeType ?? "",
      refetch,
    };
  }, [
    initialComposeType,
    messageId,
    emailThreadId,
    subject,
    to,
    teamAccountUser?.email,
    refetch,
  ]);

  const [state, dispatch] = useReducer<
    typeof ComposerReducer,
    ComposerContextType
  >(ComposerReducer, InitialState, initializerFunction);
  useEffect(() => {
    dispatch({
      type: "RESET",
      payload: initializerFunction(),
    });
  }, [initializerFunction]);
  useEffect(() => {
    setInitialComposeType(state.composeType);
  }, [state.composeType, setInitialComposeType]);
  return (
    <ComposerContext.Provider value={{ state, dispatch }}>
      {children}
    </ComposerContext.Provider>
  );
};

export default ComposerProvider;
