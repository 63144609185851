import { useContext } from "react";
import { useTarget } from "~/providers/GithubTargetProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import type { RepoTarget, UserTarget } from "@openqlabs/drm-db";
import { api } from "~/utils/api";
import ComposerContext from "../ComposerStore/ComposerContext";
import { useTeamTargetContact } from "~/providers/TeamTargetContactProvider";

const SendButton = ({ refetch }: { refetch: () => void }) => {
  const { target } = useTarget<RepoTarget | UserTarget>();
  const { teamTargetContact } = useTeamTargetContact();
  const { state, dispatch } = useContext(ComposerContext);
  const { to, from, cc, bcc, body, emailThreadId, subject, messageId } = state;
  const [teamAccountUser] = useTeamAccountUser();
  const { mutate: createEmail } = api.email.addEmail.useMutation({
    onSuccess: refetch,
  });
  const { mutate: createNote } = api.note.addNote.useMutation({
    onSuccess: refetch,
  });
  const { activeTeamAccount } = useTeamAccount();

  const { composeType } = state;
  const setComposeType = (composeType: string) => {
    dispatch({ type: "SET_COMPOSE_TYPE", payload: composeType });
  };
  const handleSend = () => {
    if (composeType === "Email") {
      const emailIngredients = {
        to,
        subject,
        from: from[0] ?? "",
        cc,
        text: body,
        sentByUser: true,
        sentAt: new Date(),
        teamAccountId: activeTeamAccount.id,
        emailThreadId: emailThreadId ?? "",
        messageId: messageId ?? "",
        teamTargetContactId: teamTargetContact?.id ?? null,
        bcc,
      };

      createEmail(emailIngredients);
    } else if (teamAccountUser) {
      const noteIngredients = {
        subject,
        from: teamAccountUser.email,
        text: body,
        teamAccountId: activeTeamAccount.id,
        teamAccountUserId: teamAccountUser.id,
        teamTargetContactId: teamTargetContact?.id ?? null,
        githubTargetId: target?.id,
      };
      createNote(noteIngredients);
    }
    const initialState = state.getInitial();
    dispatch({ type: "RESET", payload: initialState });
    setComposeType("");
  };
  return (
    <div className="flex h-14 content-center items-center rounded-b-lg border-x border-b px-8">
      <button
        onClick={handleSend}
        className="h-min rounded-lg border-2  bg-accent p-1 px-3 text-white"
      >
        Send
      </button>
    </div>
  );
};

export default SendButton;
