import type { ComposerContextType } from "./ComposerContextType";

const initialState: ComposerContextType = {
  to: [] as string[],
  cc: [] as string[],
  bcc: [] as string[],
  html: "",
  sentByUser: true,
  sentAt: new Date(),
  replyingTo: "",
  from: [],
  subject: "",
  body: "",
  refetch: () => {
    console.info("refetch not implemented");
  },
  composeType: "",
  getInitial: () => initialState,
  emailThreadId: "",
  messageId: "",
};
export default initialState;
