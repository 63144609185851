import { useRef } from "react";

export default function ContentEditable({
  setValue,
  placeholder,
}: {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  rows?: number;
}) {
  const emailRef = useRef<HTMLDivElement>(null);
  const handleInput = () => {
    if (emailRef.current) {
      setValue(emailRef.current.innerText ?? "");
    }
  };
  return (
    <div className="border-y-none relative h-max w-full max-w-[600px]  flex-1 rounded border-x  bg-white  ">
      <div
        onInput={handleInput}
        ref={emailRef}
        contentEditable={true}
        className={` min-h-[600px] w-full max-w-[600px] break-words  p-8 focus:outline-none`}
        aria-label={placeholder}
        spellCheck={false}
      />
    </div>
  );
}
