import { createContext, useContext, type ReactNode } from "react";
import useId from "~/hooks/useId";
import { RouterOutputs, api } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";

export type TeamRenderTarget =
  | RouterOutputs["targetContact"]["getTargetsByCampaignId"]["items"][number]
  | null;
export type TeamRenderTargetDeep = RouterOutputs["targetContact"]["get"] | null;

const targetContactContext = createContext<{
  targetContact: TeamRenderTargetDeep;
  isLoading: boolean;
  updateTargetContact: (targetContact: Partial<TeamRenderTargetDeep>) => void;
  setTargetContact: (targetContact: TeamRenderTargetDeep) => void;
  refetch: () => void;
}>({
  targetContact: null,
  isLoading: true,
  updateTargetContact: () => {
    return null;
  },
  setTargetContact: () => {
    return null;
  },
  refetch: () => {
    return null;
  },
});

export const useTargetContact = () => {
  const context = useContext(targetContactContext);
  return {
    ...context,

    targetContact: context.targetContact as NonNullable<TeamRenderTargetDeep>,
  };
};

export const TargetContactProvider = ({
  children,
  id,
  hasPlaceholder,
}: {
  children: ReactNode | ReactNode[];
  id?: string;
  hasPlaceholder?: boolean;
}) => {
  const currentId = useId("targetContactId", id);
  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id ?? "";
  const utils = api.useUtils();

  const handleUpdateTargetContact = (
    targetContact: Partial<TeamRenderTargetDeep>
  ) => {
    utils.targetContact.get.setData(
      { teamAccountId, id: targetContact?.id as string },
      targetContact as RouterOutputs["targetContact"]["get"]
    );
  };

  const { mutate: updateTargetContact } = api.targetContact.update.useMutation({
    onSuccess: (data) => {
      handleUpdateTargetContact(data);
    },
  });
  const {
    data: targetContact,
    refetch,
    isLoading,
  } = api.targetContact.get.useQuery({
    teamAccountId: teamAccountId,
    id: currentId,
  });
  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "TargetContactProvider.tsx"));
  };
  const targetContactUpdater = (
    targetContact: Partial<TeamRenderTargetDeep>
  ) => {
    if (!targetContact) throw new Error("statefulTargetContact is null");
    updateTargetContact({
      ...targetContact,
      id: targetContact?.id as string,
      teamAccountId: teamAccountId,
    });
  };

  if (!targetContact) {
    if (hasPlaceholder) {
      return children;
    }

    return <></>;
  } else
    return (
      <targetContactContext.Provider
        value={{
          targetContact,
          setTargetContact: handleUpdateTargetContact,
          updateTargetContact: targetContactUpdater,
          refetch: handleRefetch,
          isLoading,
        }}
      >
        {children}
      </targetContactContext.Provider>
    );
};
