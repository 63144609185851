import { createContext, useContext, type ReactNode } from "react";
import useId from "~/hooks/useId";
import { api } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";
import type { TeamTargetContact } from "~/server/api/model/target";

const teamTargetContactContext = createContext<{
  teamTargetContact: TeamTargetContact;
  isLoading: boolean;
  updateTargetContact: (teamTargetContact: Partial<TeamTargetContact>) => void;
  refetch: () => void;
}>({
  teamTargetContact: null,
  isLoading: true,
  updateTargetContact: () => {
    return null;
  },
  refetch: () => {
    return null;
  },
});

export const useTeamTargetContact = () => {
  const context = useContext(teamTargetContactContext);
  return {
    ...context,

    teamTargetContact:
      context.teamTargetContact as NonNullable<TeamTargetContact>,
  };
};

export const TeamTargetContactProvider = ({
  children,
  id,
  hasPlaceholder,
}: {
  children: ReactNode | ReactNode[];
  id?: string;
  hasPlaceholder?: boolean;
}) => {
  const currentId = useId("teamTargetContactId", id);
  const logger = useLogger();
  const { activeTeamAccount } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id ?? "";
  const utils = api.useUtils();
  const { mutate: updateTargetContact } =
    api.teamTargetContact.update.useMutation({
      onSuccess: (data) => {
        utils.teamTargetContact.get.setData(
          {
            teamAccountId: teamAccountId,
            id: currentId,
          },
          data
        );
      },
    });
  const {
    data: teamTargetContact,
    isLoading,
    refetch,
  } = api.teamTargetContact.get.useQuery({
    teamAccountId: teamAccountId,
    id: currentId,
  });
  const handleRefetch = () => {
    refetch().catch((err) => logger.error(err, "TargetContactProvider.tsx"));
  };
  const teamTargetContactUpdater = (
    teamTargetContact: Partial<TeamTargetContact>
  ) => {
    if (!teamTargetContact?.id)
      throw new Error("statefulTeamTargetContact is null");
    updateTargetContact({
      ...teamTargetContact,
      id: teamTargetContact?.id,
      teamAccountId: teamAccountId,
    });
  };
  if (!teamTargetContact) {
    if (hasPlaceholder) {
      return children;
    }

    return <></>;
  } else {
    return (
      <teamTargetContactContext.Provider
        value={{
          teamTargetContact: teamTargetContact as TeamTargetContact,
          updateTargetContact: teamTargetContactUpdater,
          refetch: handleRefetch,
          isLoading,
        }}
      >
        {children}
      </teamTargetContactContext.Provider>
    );
  }
};
