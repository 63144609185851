import React, { useContext } from "react";
import ContentEditable from "~/components/base/ContentEditable";
import ComposerContext from "../ComposerStore/ComposerContext";
import type { ComposerContextComplete } from "../ComposerStore/ComposerContextType";
const ComposerBody = () => {
  const { state, dispatch } =
    useContext<ComposerContextComplete>(ComposerContext);
  const { subject, body } = state;
  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "UPDATE_SUBJECT", payload: e.target.value });
  };

  const handleBodyChange = (value: string) => {
    dispatch({ type: "UPDATE_BODY", payload: value });
  };
  return (
    <div className="flex h-max flex-1 flex-col overflow-y-auto">
      <div className="flex gap-4 border-x border-b px-8 py-4 ">
        <strong>Subject:</strong>
        <input
          onChange={handleSubjectChange}
          value={subject}
          className="focus-visible:outline-none"
        />
      </div>
      <ContentEditable
        className="text-currentColor  ring-none h-fit  resize-none rounded-none border-x border-y-0 border-divider bg-transparent px-8 outline-none focus:ring-transparent"
        value={body}
        setValue={handleBodyChange}
      />
    </div>
  );
};

export default ComposerBody;
