import { createContext } from "react";
import InitialState from "./ComposerInitialState";

import type {
  ActionOptionsWithPayload,
  ComposerContextComplete,
} from "./ComposerContextType";
const ComposerContext = createContext<ComposerContextComplete>({
  state: InitialState,
  dispatch: () => ({}) as ActionOptionsWithPayload,
});

export default ComposerContext;
